import { Injectable } from "@angular/core";
import {UtilityServiceProxy} from "../../../../shared/service-proxies/service-proxies";
import {HttpBackend, HttpClient, HttpParams} from "@angular/common/http";
import {EnvironmentService} from "../../../environment.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor(
      private http: HttpClient,
      private handler: HttpBackend,
      private env: EnvironmentService,
      private utilityServiceProxy: UtilityServiceProxy
  ) {
    this.http = new HttpClient(this.handler);
  }

  getSignedUrl(params: UploadMetadata) {
    const body = {
      EntityId: String(params.entityId),
      Entity: params.entity,
      Role: params.role,
      ApiUrl: params.apiUrl,
      ClientId: params.clientId,
    };

    const key = params.fileName;
    return this.utilityServiceProxy.getAwsPreSignedUrlV2(key, body);
  }

  uploadFile(
      params:FileUploadParams
  ): Promise<{
    objectUrl: string;
    clientId: string;
  }> {
    if (!params.metadata.role) params.metadata.role = "yachtowner";
    return new Promise((resolve, reject) => {
      if (params.file) {
        this.getSignedUrl(params.metadata).subscribe({
          next: (response) => {
            const presignedUrl = response.preSignedUrl;
            const objectUrl = response.objectUrl;
            this.http
                .put(presignedUrl, params.file, {
                  headers: {
                    "Content-Type": "application/octet-stream",
                    "x-amz-meta-EntityId": params.metadata.entityId.toString(),
                    "x-amz-meta-Entity": params.metadata.entity.toString(),
                    "x-amz-meta-Role": params.metadata.role.toString(),
                    "x-amz-meta-Apiurl": this.env.apiUrl.toString(),
                    "x-amz-meta-ClientId": params.metadata.clientId.toString(),
                  },
                })
                .subscribe({
                  next: (res) => {
                    console.log(res);
                    resolve({ objectUrl, clientId: params.metadata.clientId });
                  },
                  error: (err) => {
                    console.log("Upload error");
                    reject(err);
                  },
                });
          },
          error: (err) => {
            console.log("Error", err);
            reject(err);
          },
        });
      } else {
        reject("No file found");
      }
    });
  }

  // File Attachments
  getSecureLink(queryParams: SecureLinkParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http.get(`${this.env}/api/services/app/FinancialAttachments/GetSecureLink?persona=2`, {
      params,
    });
  }

  getSecureOfflineLink(queryParams: any) {
    return this.http.post(`${this.env}/api/services/app/VendorProspect/GetFinancialDocumentForProspect`, queryParams);
  }
}


export interface UploadMetadata {
  fileName: string;
  fileType?: string;
  entityId?: number;
  entity: string;
  role?: string;
  apiUrl?: string;
  clientId?: string;
  isPrimary?: boolean;
}

export interface FileUploadParams {
  fileName: string;
  file: File;
  metadata:UploadMetadata
}

export enum UploadEntities {
  SERVICE_ORDER = "SERVICE_ORDER",
  TECHNICIAN_SERVICE_ORDER_SCHEDULE = "TECHNICIAN_SERVICE_ORDER_SCHEDULE",
  YACHT_PROFILE_IMAGES = "YACHT_PROFILE_IMAGES",
  PROFILE_PICTURE_IMAGE = "PROFILE_PICTURE_IMAGE",
  GALLERY_IMAGE = "GALLERY_IMAGE",
  RECOMMENDED_SERVICE = "RECOMMENDED_SERVICE",
  CHECKLIST_ITEM = "CHECKLIST_ITEM",
  MULTIPOINT_INSPECTION_CHECKLIST = "MULTIPOINT_INSPECTION_CHECKLIST",
  VISUAL_INSPECTION_CHECKLIST = "VISUAL_INSPECTION_CHECKLIST",
}

export interface AttachmentUploadParams {
  fileName: string;
  estimateId?: number;
  invoiceId?: number;
  yachtId?: number;
}

export interface DeleteAttachmentParams {
  estimateId?: number;
  invoiceId?: number;
  id: number;
}

export type SecureLinkParams = {
  id?: number;
  estimateId?: number;
  invoiceId?: number;
};
export type SecureOfflineLinkParams = {
  id?: number;
  code?: string;
  estimateId?: number;
  invoiceId?: number;
};

export interface FinancialAttachmentDto {
  documentType?: number;
  estimateId?: number;
  invoiceId?: number;
  fileName: string;
  id: number;
  name: string;
  ownerUserId: number;
  state: number;
}

export interface FileUploadModel {
  fileName: string;
  file: Buffer | Uint8Array | Blob | string;
  metadata: { [key: string]: string };
}