import { Injectable } from "@angular/core";
import { BehaviorSubject, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseService } from "src/app/shared/base.service";
import { EnvironmentService } from "src/app/environment.service";

@Injectable({
  providedIn: "root",
})
export class OnBoardingService {
  onboardingStepSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  onboardingStep$ = this.onboardingStepSubject.asObservable();

  constructor(private httpClient: HttpClient, private baseService: BaseService, private env: EnvironmentService) {
    (async () => {
      try {
        console.log("OnboardingService");
        await this.getOnboardingStep();
      } catch (error) {
        console.error("Unable to determine onboarding status.", error);
      }
    })();
  }

  setOnboardingStep(step: number) {
    this.onboardingStepSubject.next(step);
  }

  getOnboardingStep(): Promise<number> {
    return new Promise((resolve, reject) => {
      //   const user = this.baseService.getSession(this.baseService.credKey);
      const stepValue = this.onboardingStepSubject.getValue();
      console.log("stepValue", stepValue);

      const onboardingStep = stepValue;
      //    || this.baseService.getSession("onboardingStep_" + user.id);
      console.log("onboardingStep", onboardingStep);

      if (onboardingStep !== undefined && onboardingStep !== null) {
        console.log("onboardingStep Here", onboardingStep);
        resolve(onboardingStep);
      } else {
        this.getOnBoardingStatus().subscribe({
          next: (res) => {
            console.log("res", res);
            this.storeOnboardingStep(res.result);
            resolve(res.result);
          },
          error: (err) => {
            console.log(err);
            reject(err);
          },
        });
      }
    });
  }

  async isOnboarding(): Promise<boolean> {
    const step = await this.getOnboardingStep();
    console.log("step", step);
    return step < OnboardingStep.COMPLETE;
  }

  storeOnboardingStep(step: number) {
    const user = this.baseService.getSession(this.baseService.credKey);
    // this.baseService.createSession("onboardingStep_" + user.id, step);
    this.onboardingStepSubject.next(step);
  }

  getOnBoardingStatus(): Observable<any> {
    return this.httpClient.get(`${this.env.apiUrl}services/app/YachtOwners/GetOnboardingStage`).pipe(
      tap((res) => {
        this.setOnboardingStep(res);
        const user = this.baseService.getSession(this.baseService.credKey);
        this.baseService.createSession("onboardingStep_" + user.id, res);
      })
    );
  }
}

export enum OnboardingStep {
  COMPLETE = 5,
}
