import { Injectable } from "@angular/core";
import { IEnvironment } from "../environments/ienvironment";
import { environment } from "../environments/environment";
import { PersonaEnum } from "./shared/types/PersonaEnum";

@Injectable({
    providedIn: "root",
})
export class EnvironmentService implements IEnvironment {
    get persona(): PersonaEnum {
        if (environment.buildType === "boatowner")
            return PersonaEnum.YACHT_OWNER;
        if (environment.buildType === "technician")
            return PersonaEnum.TECHNICIAN;
        return PersonaEnum.NONE;
    }

    constructor() {
        //
    }

    get apiHost() {
        return environment.apiHost;
    }

    get apiUrl() {
        return environment.apiUrl;
    }

    get abpUrl() {
        return environment.abpUrl;
    }

    get proxyServiceUrl() {
        return environment.proxyServiceUrl;
    }

    get enableDebugTools() {
        return environment.enableDebugTools;
    }

    get logLevel() {
        return environment.logLevel;
    }

    get production() {
        return environment.production;
    }

 

    get geoLocationTimer() {
        return environment.geoLocationTimer;
    }

    get buildType() {
        return environment.buildType;
    }
}
