<ion-header>
  <div class="flex justify-between items-center">
    <button (click)="closeModal()" class="w-16 h-16 flex items-center justify-center">
      <div class="flex w-10 h-10 flex-none items-center justify-center rounded-full bg-brandSunsetOrange">
        <span class="bbicon-chevron-left text-2xl text-white"></span>
      </div>
    </button>
  </div>
</ion-header>

<div class="text-center p-6 rounded-lg max-w-sm mx-auto">
  <h2 class="text-xl font-bold text-brandPrimaryBlue mb-2">{{ title }}</h2>
  <p class="text-muted-foreground mb-4">{{ description }}</p>
  <div class="flex flex-col items-center" *ngIf="image">
    <p class="font-semibold">{{LANGUAGE_DATA?.CAPTAIN_TALK.ADD_SYSTEM_SCREEN_LABELS.EXAMPLE_LABEL | translate}}:</p>
    <img [src]="image" alt="Example of serial number location" class="w-full h-auto rounded-lg mb-4" />
  </div>
  <app-button
    (click)="closeModal()"
    [label]="LANGUAGE_DATA?.ACTION_BUTTONS.CLOSE | translate"
    [variant]="'secondary'"
    class="bg-secondary text-secondary-foreground hover:bg-secondary/80 py-2 px-4 rounded-lg"
  ></app-button>
</div>
