import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";

// Directives
import { SharedDirectivesModule } from "../utilities/directives/shared-directives-module";
// TODO: Investigate errors reported on this directive
import { ScrollToViewDirective } from "../utilities/directives/scroll-to-view.directive";

// Services
import { EnvironmentService } from "../environment.service";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FILE_STORAGE_SERVICE, GallaryStorage } from "./imageManagement/file-picker/client-side-file-storage";
import { FileCacheInterceptor, FILE_CACHE_PROVIDER, NullCachedFilesProvider } from "./file-cache.interceptor";
import { ToastService } from "./message/toast.service-impl";
import { TOAST_SERVICE } from "./message/toast.service";

// Pipes
import { RelativeDatePipe } from "../utilities/pipes/relative-date.pipe";
import { RepeatPipe } from "./pipes/repeat.pipe";
import { RemoveNewlinePipe } from "../utilities/pipes/remove-newline.pipe";
import { UtcToPreferredTimezonePipe } from "../utilities/pipes/utc-to-preferred-timezone.pipe";

// Additional Imports
import { NgHttpLoaderModule } from "ng-http-loader";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TranslateModule } from "@ngx-translate/core";
import { SwiperModule } from "swiper/angular";
import { BBTextAreaModule } from "./textarea/textarea.module";
import { ImageCropperModule } from "ngx-image-cropper";

// TODO: Investigate and remove if not needed
import { BbHeaderModule } from "./bb-header/bb-header.module";
import { ModalHeaderModule } from "./modal-header/modal-header.module";

// Make Standalone
import { RowComponent } from "./row/row.component";
import { InfoListCardComponent } from "./info-list-card/info-list-card.component";
import { AiHeaderComponent } from "../captain-talk/components/ai-header/ai-header.component";
import { YachtOwnerTabMenuComponent } from "./yacht-owner-tab-menu/yacht-owner-tab-menu.component";
import { VendorSelectComponent } from "../yacht-owner/serviceRequest/vendor-select/vendor-select.component";
import { ServiceSelectComponent } from "../yacht-owner/serviceRequest/service-select/service-select.component";
import { BaseTwoLayoutComponent } from "../captain-talk/components/base-two-layout/base-two-layout.component";
import { BasicAlertComponent } from "./basic-alert/basic-alert.component";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { InAppModalComponent } from "./in-app-modal/in-app-modal.component";
// Move to Shared UI Module
import { ChipComponent } from "./chip/chip.component";

// Form Components
import { InputComponent } from "./ui/input/input.component";
import { SelectComponent } from "./select/select.component";
import { SelectListComponent } from "./select-list/select-list.component";
import { ChatInputComponent } from "./chat-input/chat-input.component";
import { SearchableListComponent } from "./searchable-list/searchable-list.component";
import { SearchInputComponent } from "./search-input/search-input.component";

import { CaptainAiTalkComponent } from "./captain-ai-talk/captain-ai-talk.component";

// Rename to RowSkeletonComponent
import { RowSkeletonComponent } from "./skeletons/row-skeleton/row-skeleton.component";
import { LoaderComponent } from "./loader/loader.component";
import { MessageRowComponent } from "./message-row/message-row.component";
import { PermissionDeniedComponent } from "./permission-denied/permission-denied.component";

// Rename to LocationPickerComponent
import { PickLocationOnMapComponent } from "./pick-location-on-map/pick-location-on-map.component";
import { GoogleMapUiComponent } from "./pick-location-on-map/google-map-ui/google-map-ui.component";

// TODO: Move to Service Provider Module
import { ServiceProviderMapComponent } from "../captain-talk/components/service-provider-map/service-provider-map.component";
import { FindVendorMapComponent } from "../yacht-owner/invite-service-providers/new-invite/find-vendor-map/find-vendor-map.component";
import { ServiceProviderListRowComponent } from "../captain-talk/components/service-provider-list-row/service-provider-list-row.component";
import { MiniServiceProviderDetailComponent } from "../captain-talk/components/mini-service-provider-detail/mini-service-provider-detail.component";
import { VendorTypesModalComponent } from "../yacht-owner/service-providers/vendorTypesModal/vendorTypesModal.component";
// TODO: Move to proper location
import { DeleteAccountComponent } from "./delete-account/delete-account.component";
// TODO: Move to Guide Module
import { PopoverButtonComponent } from "./popover-button/popover-button.component";
import { PopoverComponent } from "./ui/popover/popover.component";
import { PopoverGuideComponent } from "./popover-guide/popover-guide.component";
import { PopoverGuideWelcomeComponent } from "./popover-guide-welcome/popover-guide-welcome.component";
import { VideoGuideComponent } from "./video-guide/video-guide.component";
import { VideoGuidePlayerComponent } from "./video-guide-player/video-guide-player.component";
// TODO: Move to Calendar Module
import { CalendarEventFormComponent } from "./calendar-event-form/calendar-event-form.component";
import { CalendarEventDetailsComponent } from "./calendar-event-details/calendar-event-details.component";
// TODO: Move to Shared Notes Module
import { NotesComponent } from "./notes/notes.component";
import { ListNotesComponent } from "./list-notes/list-notes.component";
// TODO: Move to image management module
import { ServiceImagesComponent } from "./service-images/service-images.component";
import { CoverImageComponent } from "./imageManagement/cover-image/cover-image.component";
import { ImageViewerComponent } from "./imageManagement/image-viewer/image-viewer.component";
import { ImagePreviewComponent } from "./image-preview/image-preview.component";
import { ImagesBrowserComponent } from "./imageManagement/images-browser/images-browser.component";
import { ImagesBrowserContainerComponent } from "./imageManagement/images-browser-container/images-browser-container.component";
// TODO: Move to make-model module
import { AddManufacturerComponent } from "./add-manufacturer/add-manufacturer.component";

// TODO: Implement in new Boat Profile
import { PromptComponent } from "./prompt/prompt.component";

// TODO: Adjust and implement. Loc: Vitals
import { NumberSpinnerComponent } from "./ui/number-spinner/number-spinner.component";

// Remove after tech detach
import { NgImageSliderModule } from "ng-image-slider";
import { ProximityAlertComponent } from "./proximity-alert/proximity-alert.component";
import { TechnicianTabMenuComponent } from "./technician-tab-menu/technician-tab-menu.component";
import { TechHeaderComponent } from "./tech-header/tech-header.component";
import { ImageContainerComponent } from "./image-container/image-container.component";
import { CapacitorMapComponent } from "./capacitor-map/capacitor-map.component";
import { ServiceRowComponent } from "./service-row/service-row.component";
import { CustomSwiperComponent } from "./custom-swiper/custom-swiper.component";
import { MultiVendorPopOverScreenComponent } from "./multi-vendor-pop-over-screen/multi-vendor-pop-over-screen.component";

// Remove Completely
import { FullScreenModalComponent } from "./modal/full-screen-modal/full-screen-modal.component";
import { BlurBackgroundModalComponent } from "./modal/blur-background-modal/blur-background-modal.component";
import { BBIconButtonModule } from "./ui/button/bb-button.module";
// Remove after adjusting Add Boat
import { ProgressStepsComponent } from "./progress-steps/progress-steps.component";
import { InteractiveChipComponent } from "./interactive-chip/interactive-chip.component";
import { DateRangeInputComponent } from "./date-range-input/date-range-input.component";
import { FlatpickrModule } from "angularx-flatpickr";
import { ShowAttachmentsComponent } from "./list-notes/show-attachments/show-attachments.component";
import { TimeFromNowPipe } from "../utilities/pipes/time-from-now.pipe";
import { AppInitService } from "../services/app-init.service";


function combinedInitializer(appInitService: AppInitService) {
  return () => {
  
    appInitService.init();

  };
}


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    InAppModalComponent,
    FullScreenModalComponent,
    ImageViewerComponent,
    YachtOwnerTabMenuComponent,
    BlurBackgroundModalComponent,
    AlertDialogComponent,
    LoaderComponent,
    TechnicianTabMenuComponent,
    ProximityAlertComponent,
    VendorSelectComponent,
    ServiceSelectComponent,
    RowComponent,
    InputComponent,
    TechHeaderComponent,
    RelativeDatePipe,
    ImageContainerComponent,
    CapacitorMapComponent,
    CoverImageComponent,
    SearchableListComponent,
    ServiceImagesComponent,
    PopoverComponent,
    ServiceRowComponent,
    PopoverButtonComponent,
    CaptainAiTalkComponent,
    RowSkeletonComponent,
    RepeatPipe,
    ChatInputComponent,
    InteractiveChipComponent,
    ChipComponent,
    MessageRowComponent,
    CustomSwiperComponent,
    PermissionDeniedComponent,
    DeleteAccountComponent,
    NumberSpinnerComponent,
    PopoverGuideComponent,
    PopoverGuideWelcomeComponent,
    PickLocationOnMapComponent,
    GoogleMapUiComponent,
    SelectComponent,
    PromptComponent,
    CalendarEventFormComponent,
    CalendarEventDetailsComponent,
    ImagePreviewComponent,
    SelectListComponent,
    VideoGuideComponent,
    VideoGuidePlayerComponent,
    InAppModalComponent,
    MultiVendorPopOverScreenComponent,
    NotesComponent,
    ListNotesComponent,
    ShowAttachmentsComponent,
    BaseTwoLayoutComponent,
    BasicAlertComponent,
    ScrollToViewDirective,
    InfoListCardComponent,
    AiHeaderComponent,
    FindVendorMapComponent,
    ServiceProviderListRowComponent,
    ServiceProviderMapComponent,
    MiniServiceProviderDetailComponent,
    VendorTypesModalComponent,
    UtcToPreferredTimezonePipe,
    TimeFromNowPipe,
    RemoveNewlinePipe,
    ImagesBrowserComponent,
    ImagesBrowserContainerComponent,
    ProgressStepsComponent,
    AddManufacturerComponent,
    SearchInputComponent,
    DateRangeInputComponent,
  ],
  exports: [
    FullScreenModalComponent,
    ImageViewerComponent,
    YachtOwnerTabMenuComponent,
    BlurBackgroundModalComponent,
    AlertDialogComponent,
    LoaderComponent,
    TechnicianTabMenuComponent,
    ProximityAlertComponent,
    RowComponent,
    CapacitorMapComponent,
    InputComponent,
    TechHeaderComponent,
    RelativeDatePipe,
    CoverImageComponent,
    SearchableListComponent,
    ServiceImagesComponent,
    ServiceRowComponent,
    PopoverButtonComponent,
    BBIconButtonModule,
    RowSkeletonComponent,
    RepeatPipe,
    ChatInputComponent,
    InteractiveChipComponent,
    ChipComponent,
    MessageRowComponent,
    BbHeaderModule,
    IonicModule,
    CustomSwiperComponent,
    GoogleMapUiComponent,
    SelectComponent,
    SelectListComponent,
    VideoGuideComponent,
    BaseTwoLayoutComponent,
    AiHeaderComponent,
    ScrollToViewDirective,
    InfoListCardComponent,
    FindVendorMapComponent,
    ServiceProviderListRowComponent,
    ServiceProviderMapComponent,
    MiniServiceProviderDetailComponent,
    VendorTypesModalComponent,
    UtcToPreferredTimezonePipe,
    RemoveNewlinePipe,
    ImagesBrowserComponent,
    ProgressStepsComponent,
    AddManufacturerComponent,
    SearchInputComponent,
    ListNotesComponent,
    DateRangeInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    NgImageSliderModule,
    NgHttpLoaderModule,
    SwiperModule,
    BBIconButtonModule,
    BBTextAreaModule,
    BbHeaderModule,
    ModalHeaderModule,
    SharedDirectivesModule,
    NgxIntlTelInputModule,
    ImageCropperModule,
    NgCircleProgressModule,
    FlatpickrModule,
  ],
  providers: [
    {
      provide: TOAST_SERVICE,
      useClass: ToastService,
    },
    {
      provide: FILE_STORAGE_SERVICE,
      useClass: GallaryStorage,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FileCacheInterceptor,
      multi: true,
    },
    {
      provide: FILE_CACHE_PROVIDER,
      useClass: NullCachedFilesProvider,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: combinedInitializer,
      deps: [AppInitService],
      multi: true,
    },
  ],
})
export class SharedModule {
  constructor() {
    document.addEventListener(
      "click",
      (e: MouseEvent) => {
        const clickedItem = (e.target as Element).id;
        if (!document.activeElement?.attributes.getNamedItem("searchbox")) {
          if (!document.activeElement?.attributes.getNamedItem("searchitem")) {
            if (clickedItem !== "dropdown-icon") {
              const ele = document.getElementsByClassName("search-drop-container");
              for (let i = 0; i < ele.length; i++) {
                if (!ele.item(i)?.classList.contains("hidden")) {
                  ele.item(i)?.classList.add("hidden");
                }
              }
            }
          }
        }
      },
      false
    );
  }
}
