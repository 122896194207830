<ion-card
  class="m-0 shadow-none rounded-xl {{
    source === 'default' ? 'border-2 border-solid border-brandAiSkyBlue mt-4 p-3' : 'px-3 py-2'
  }}"
>
  <div *ngIf="isFeedBackGiven">
    {{ LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.FEEDBACK_GIVEN_TEXT | translate }}
  </div>
  <div *ngIf="!isFeedBackGiven">
    <ng-container *ngIf="source === 'default'">
      <h3 class="text-black text-xl m-0">{{ LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.TITLE | translate }}</h3>
      <span class="text-base">{{ LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.DESCRIPTION | translate }}</span>
    </ng-container>
    <span class="text-base mb-1" *ngIf="source === 'conversation' || source === 'pre_prompt'">
      {{ LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.QUESTION | translate }}
    </span>
  </div>
  <ng-container *ngIf="source === 'default' && !isFeedBackGiven">
    <div class="flex justify-between py-2 gap-2">
      <button
        [disabled]="isSendingPositiveFeedback"
        class="flex items-center justify-center border-[1px] border-solid border-brandAiSkyBlue bg-brandAiLightBlue w-[48%] rounded-full p-2"
        (click)="thumbsUpClick()"
      >
        <span class="text-brandDeepSeaBlue mr-2 font-medium text-lg">{{
          LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.BUTTON_FIXED_TEXT | translate
        }}</span>
        <span class="bbicon-thumb-up text-4xl text-brandDeepSeaBlue" *ngIf="!isSendingPositiveFeedback"></span>
        <ion-spinner name="crescent" *ngIf="isSendingPositiveFeedback"></ion-spinner>
      </button>

      <button
        class="flex items-center justify-center border-[1px] border-solid border-brandAiSkyBlue bg-brandAiLightBlue w-[48%] rounded-full p-2"
        (click)="thumbsDownClick()"
        [disabled]="isSendingNegativeFeedback"
      >
        <span class="text-brandDeepSeaBlue mr-2 font-medium text-lg">{{
          LANGUAGE_DATA?.CAPTAIN_TALK.FEEDBACK.BUTTON_NOT_FIXED_TEXT | translate
        }}</span>
        <span class="bbicon-thumb-down text-4xl text-brandDeepSeaBlue" *ngIf="!isSendingNegativeFeedback"></span>
        <ion-spinner name="crescent" *ngIf="isSendingNegativeFeedback"></ion-spinner>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="source === 'conversation' || (source === 'pre_prompt' && !isFeedBackGiven)">
    <div class="flex items-center gap-3 mt-2" *ngIf="!isSendingNegativeFeedback && !isSendingPositiveFeedback">
      <button (click)="thumbsUpClick()" [disabled]="isSendingPositiveFeedback">
        <span class="bbicon-thumb-up text-4xl text-brandDeepSeaBlue" *ngIf="!isSendingPositiveFeedback"></span>
      </button>
      <button (click)="thumbsDownClick()" [disabled]="isSendingNegativeFeedback">
        <span class="bbicon-thumb-down text-4xl text-brandDeepSeaBlue" *ngIf="!isSendingNegativeFeedback"></span>
      </button>
    </div>
    <ion-spinner name="crescent" *ngIf="isSendingNegativeFeedback || isSendingPositiveFeedback"></ion-spinner>
  </ng-container>
</ion-card>
