import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { BehaviorSubject } from 'rxjs';


export interface UpdateInfo {
  availableVersion: string;
  needsUpdate: boolean;
  updateInProgress?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  public onUpdateAvailable = new BehaviorSubject<UpdateInfo>({
    availableVersion: '',
    needsUpdate: false,
  });
  constructor() { }

  async checkForUpdate() {
    if(Capacitor.isNativePlatform()){
      try {
        const result = await AppUpdate.getAppUpdateInfo();
       if(result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE){
        this.onUpdateAvailable.next({
          availableVersion: Capacitor.getPlatform() ==  "ios" ? result.availableVersionName : result.availableVersionCode,
          needsUpdate: true,
        });
      }else if(result.updateAvailability === AppUpdateAvailability.UPDATE_IN_PROGRESS){
        this.onUpdateAvailable.next({
          availableVersion: Capacitor.getPlatform() ==  "ios" ? result.availableVersionName : result.availableVersionCode,
          needsUpdate: true,
          updateInProgress: true,
        })
      } else {
        this.onUpdateAvailable.next({
          availableVersion: '',
          needsUpdate: false,
        });
      }
       
      } catch (error) {
        console.error('Error checking for update:', error);
       
      }
    }else{
      console.log('Not a native platform');
     
    }

  }


 

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };
}
