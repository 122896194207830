import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { EnvironmentService } from "src/app/environment.service";
import { partFinderPayload } from "./part-intro/part-intro.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LocateSerialNumberComponent } from "src/app/captain-talk/components/locate-serial-number/locate-serial-number.component";
import { ModalController } from "@ionic/angular";
import { PartConfirmationResponse } from "./part-interface";
import {
  UNIT_OF_MEASUREMENTS_ENGINE,
  UNIT_OF_MEASUREMENTS_STABILIZER,
} from "src/app/yacht-owner/boat-manager/boat-profile/add-vessel-information/enum";
import {
  ComponentDto,
  IComponentDto,
  SymptomPartTypeEnum,
  SystemTypeEnum,
} from "src/shared/service-proxies/service-proxies";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PartService {
  onSetSystems = new BehaviorSubject<any[]>(null);

  private processedDataSubject = new BehaviorSubject<any>(null);
  processedData$ = this.processedDataSubject.asObservable();

  private serialNumberSubject = new BehaviorSubject<string>("");
  serialNumber = this.serialNumberSubject.asObservable();

  private partConfirmationResponseSubject = new BehaviorSubject<any>(null);
  partConfirmationResponse$ = this.partConfirmationResponseSubject.asObservable();

  // System Confirmation Subject
  private systemConfirmationResponseSubject = new BehaviorSubject<PartConfirmationResponse>(null);
  systemConfirmationResponse$ = this.systemConfirmationResponseSubject.asObservable();

  // System Identification Subject
  private currentIdentifiedSystemSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentIdentifiedSystem$ = this.currentIdentifiedSystemSubject.asObservable();

  // Partfinder Subject
  private currentPartFinderSystemSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentPartFinderSystem$ = this.currentPartFinderSystemSubject.asObservable();

  private systemImageSubject = new BehaviorSubject<string>("");
  systemImage = this.systemImageSubject.asObservable();

  private compatiblePartsSubject = new BehaviorSubject<any>(null);
  compatibleParts$ = this.compatiblePartsSubject.asObservable();

  private shopPartsSubject = new BehaviorSubject<any>(null);
  shopParts$ = this.shopPartsSubject.asObservable();

  private selectedPartSubject = new BehaviorSubject<any>(null);
  selectedPart$ = this.selectedPartSubject.asObservable();

  //
  private currentIdentifyComponentDataSubject: BehaviorSubject<IIdentificationProcess> =
    new BehaviorSubject<IIdentificationProcess>(null);
  currentIdentifyComponentData$ = this.currentIdentifyComponentDataSubject.asObservable();

  private currentIdentifiedManualInputSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentIdentifiedManualInput$ = this.currentIdentifiedManualInputSubject.asObservable();

  private currentAIIdentifiedSystemDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentAIIdentifiedSystemData$ = this.currentAIIdentifiedSystemDataSubject.asObservable();

  public currentPartFinderReturnUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  //

  systemFinderForm: FormGroup;

  enumPartTypeMapping = {
    [SystemTypeEnum.Engine]: ComponentTypeConst.GENERAL_ENGINE,
    [SystemTypeEnum.Generator]: ComponentTypeConst.GENERATOR,
    [SystemTypeEnum.Stabilization]: ComponentTypeConst.GENERAL_STABILIZER,
    [SystemTypeEnum.AirHeat]: ComponentTypeConst.AIR_CONDITIONER,
    [SystemTypeEnum.WaterMaker]: ComponentTypeConst.WATER_MAKER,
    [SystemTypeEnum.Refrigerator]: ComponentTypeConst.REFRIGERATOR,
    [SystemTypeEnum.IceMaker]: ComponentTypeConst.ICE_MAKER,
    [SystemTypeEnum.Toilet]: ComponentTypeConst.TOILET,
    [SystemTypeEnum.Zincs]: ComponentTypeConst.ZINC,
  };

  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private router: Router
  ) {
    this.initializeForm();
  }

  setCurrentIdentifiedManualInput(data: any) {
    this.currentIdentifiedManualInputSubject.next(data);
  }

  getCurrentIdentifiedManualInput() {
    return this.currentIdentifiedManualInputSubject.value;
  }

  setCurrentIdentifiedComponentData(
    yachtId: number,
    component: IComponentDto,
    processMeta?: IIdentificationProcessMeta
  ): void {
    const data: IIdentificationProcess = {
      component,
      yachtId,
      processMeta,
    };
    this.currentIdentifyComponentDataSubject.next(data);
  }

  getCurrentIdentifiedComponentData(): IIdentificationProcess {
    return this.currentIdentifyComponentDataSubject.value;
  }

  setCurrentAIIdentifiedSystemData(data: MainParts) {
    this.currentAIIdentifiedSystemDataSubject.next(data);
  }

  clearCurrentIdentifiedComponentData() {
    this.currentIdentifyComponentDataSubject.next(null);
  }

  getCurrentAIIdentifiedSystemData(): MainParts {
    return this.currentAIIdentifiedSystemDataSubject.value;
  }

  clearCurrentAIIdentifiedSystemData() {
    this.currentAIIdentifiedSystemDataSubject.next(null);
  }

  //

  setCurrentIdentifiedSystem(data: any) {
    this.currentIdentifiedSystemSubject.next(data);
  }

  getCurrentIdentifiedSystem() {
    return this.currentIdentifiedSystemSubject.value;
  }

  setCurrentPartFinderSystem(data: PartFinderSystemData | null) {
    this.currentPartFinderSystemSubject.next(data);
  }

  setCurrentPartFinderPart(part: any) {
    // const currentPartFinderSystem = this.getCurrentPartFinderSystem();
    // this.currentPartFinderSystemSubject.next({
    //   ...currentPartFinderSystem,
    //   part,
    // });
  }

  getCurrentPartFinderSystem(): PartFinderSystemData {
    return this.currentPartFinderSystemSubject.value;
  }

  getPartTypeId(systemId: number): number {
    // First, check if the systemId is in the enum mapping
    const fromBoatSpecifics = localStorage.getItem("isFromBoatSpecifics");

    if (fromBoatSpecifics) {
      if (systemId in this.enumPartTypeMapping) {
        console.log("System ID:", systemId);
        return this.enumPartTypeMapping[systemId as SystemTypeEnum];
      }
    }

    const systemTypeId = ComponentTypeConst.getIdForApi(systemId);
    return systemTypeId;
  }

  // Data for the chatbot
  setSystems(systems: { key: number; value: string }[]) {
    this.onSetSystems.next(systems);
  }

  getSystemType(systemId: number): number {
    const fromBoatSpecifics = localStorage.getItem("isFromBoatSpecifics");

    if (fromBoatSpecifics) {
      return systemId;
    }

    const systemTypeId = ComponentTypeConst.getIdForApi(systemId);
    return systemTypeId;
  }

  setSystemConfirmationResponse(data: any) {
    this.systemConfirmationResponseSubject.next(data);
  }

  getSystemConfirmationResponse() {
    return this.systemConfirmationResponseSubject.value;
  }

  loadSystemConfirmationResponse() {
    const storedData = localStorage.getItem("systemConfirmationResponse");
    if (storedData) {
      this.systemConfirmationResponseSubject.next(JSON.parse(storedData));
    }
  }

  clearSystemConfirmationResponse() {
    this.partConfirmationResponseSubject.next(null);
    localStorage.removeItem("systemConfirmationResponse");
  }

  setProcessedData(data: any) {
    const processedData = this.processData(data);

    this.processedDataSubject.next(processedData);
    localStorage.setItem("processedData", JSON.stringify(processedData));
  }

  async showModal(partType?: SymptomPartTypeEnum) {
    const data: {
      title: string;
      description: string;
      image?: string;
    } = ComponentTypeConst.determineSystemModalData(Number(partType));
    const modal = await this.modalCtrl.create({
      component: LocateSerialNumberComponent,
      cssClass: "ai-alerts-modal",
      backdropDismiss: false,
      componentProps: { data },
    });
    await modal.present();
  }

  /*SERIAL NUMBER */
  setSerialNumber(serialNumber: string) {
    this.serialNumberSubject.next(serialNumber);
    localStorage.setItem("serialNumber", JSON.stringify(serialNumber));
  }

  getSerialNumber() {
    return this.serialNumberSubject.value;
  }

  loadSerialNumber() {
    const storedData = localStorage.getItem("serialNumber");
    if (storedData) {
      this.serialNumberSubject.next(JSON.parse(storedData));
    }
  }

  extractMainParts(data: any): MainParts {
    const { main_parts } = data;

    return {
      manufacturer: main_parts.manufacturer?.trim() ?? "",
      model_number: main_parts.model_number?.trim() ?? "",
      serial_number: main_parts.serial_number?.trim() ?? "",
      year_of_manufacture: main_parts.manufactured_date?.trim() ?? "",
    };
  }

  clearSerialNumber() {
    this.serialNumberSubject.next(null);
    localStorage.removeItem("serialNumber");
  }

  /*FOR PARTS*/
  setPartConfirmationResponse(data: any) {
    this.partConfirmationResponseSubject.next(data);
    localStorage.setItem("partConfirmationResponse", JSON.stringify(data));
  }

  getPartConfirmationResponse() {
    return this.partConfirmationResponseSubject.value;
  }

  loadPartConfirmationResponse() {
    const storedData = localStorage.getItem("partConfirmationResponse");
    if (storedData) {
      this.partConfirmationResponseSubject.next(JSON.parse(storedData));
    }
  }

  clearPartConfirmationResponse() {
    this.partConfirmationResponseSubject.next(null);
    localStorage.removeItem("partConfirmationResponse");
  }

  private initializeForm(): void {
    this.systemFinderForm = this.fb.group({
      serialNumber: [""],
      YearBuilt: [""],
      ManufacturerId: [""],
      Manufacturer: [""],
      Model: [""],
      VesselModelId: [""],
      systemPartName: [""],
    });
  }

  processFormValues(data: any): FormGroup {
    if (!data) return this.systemFinderForm;

    this.systemFinderForm.patchValue({
      serialNumber: data.serial_number || "",
      YearBuilt: data.year_of_manufacture || "",
      ManufacturerId: data.ManufacturerId || "",
      Manufacturer: data.manufacturer || "",
      Model: data.model || "",
      VesselModelId: data.VesselModelId || "",
      systemPartName: data.systemPartName || "",
    });

    return this.systemFinderForm;
  }

  dataToForm(data: any): FormGroup {
    if (!data) return this.systemFinderForm;

    this.systemFinderForm.patchValue({
      serialNumber: data?.serial_number || "",
      YearBuilt: data?.year_of_manufacture || "",
      ManufacturerId: data?.ManufacturerId || "",
      Manufacturer: data?.manufacturer || "",
      Model: data?.model_number || "",
      VesselModelId: data?.VesselModelId || "",
      systemPartName: data?.systemPartName || "",
    });

    return this.systemFinderForm;
  }

  processData(data: any): any {
    if (typeof data === "object" && data !== null) {
      if (Array.isArray(data)) {
        return data.map((item) => this.processData(item));
      } else {
        const processedData: { [key: string]: any } = {};
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            processedData[key] = this.processData(data[key]);
          }
        }
        return processedData;
      }
    } else if (typeof data === "string" && data.toLowerCase().includes("unable to determine")) {
      // return "N/A";
      // if the value had been previously provided, return it instead of "N/A"
      return "";
    }
    return data;
  }
  extractSystemInfoFromCamera(data: SystemInfo): SystemInfo {
    const { system_type, manufacturer, part_type, model_number, serial_number, specifications } = data;

    return {
      system_type,
      manufacturer,
      part_type,
      model_number,
      serial_number,
      specifications,
    };
  }

  toCamelCase(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  replaceUnableToDetermine(value: string): string {
    return value.trim().toLowerCase() === "unable to determine" ? "N/A" : value;
  }

  checkStabilizerType(type) {
    if (type === "gyro") {
      return 16;
    } else if (type === "fins") {
      return 15;
    } else {
      return 15; //default
    }
  }

  checkTheSystemType(component) {
    switch (component.engineType) {
      case "inboard":
        return 13;
      case "outboard":
        return 14;

      default:
        return 14; //default
    }
  }

  processInferredInfo(jsonInput: string): InferredInfo {
    try {
      // Parse the JSON input
      const data: JsonInput = JSON.parse(jsonInput);
      const inferredInfo: string = data.inferred_info;

      // Split the inferred_info into lines
      const lines: string[] = inferredInfo.split("\n");

      const result: InferredInfo = {};
      let currentKey = "";

      lines.forEach((line: string) => {
        line = line.trim();
        if (line === "") return;

        if (line.includes(":")) {
          const [key, ...valueParts] = line.split(":");
          currentKey = this.toCamelCase(key.trim());
          let value: string = valueParts.join(":").trim();

          value = this.replaceUnableToDetermine(value);

          result[currentKey] = value;
        } else if (line.startsWith("-")) {
          // Handle bullet points
          if (!Array.isArray(result[currentKey])) {
            result[currentKey] = [];
          }
          let bulletPoint = line.substring(1).trim();
          bulletPoint = this.replaceUnableToDetermine(bulletPoint);
          (result[currentKey] as string[]).push(bulletPoint);
        } else {
          // If there's no colon and it doesn't start with a dash,
          // append it to the previous key's value
          if (currentKey) {
            if (typeof result[currentKey] === "string") {
              result[currentKey] += " " + line;
            } else if (Array.isArray(result[currentKey])) {
              (result[currentKey] as string[]).push(line);
            }
          }
        }
      });

      return result;
    } catch (error) {
      console.error("Error processing JSON:", error);
      return { error: "Invalid JSON input" };
    }
  }

  extractEngineInfo(data: any): PartsInfo {
    const { success, inferred_info, accuracy_level, accuracy_enum, manual_input } = data;

    const parsed_info: PartsInfo["parsed_info"] = {
      manufacturer: "",
      model: "",
      serialNumber: "",
      estimatedYear: "",
      specifications: {},
      features: [],
      compatibility: "",
      maintenance: [],
    };
    let lines = [];
    if (inferred_info) {
      lines = inferred_info.split("\n");
    }

    let currentSection = "";

    lines.forEach((line) => {
      if (line.includes(":")) {
        const [key, value] = line.split(":").map((s) => s.trim());
        switch (key) {
          case "Manufacturer":
            parsed_info.manufacturer = value;
            break;
          case "Model":
            parsed_info.model = value;
            break;
          case "Serial Number":
            parsed_info.serialNumber = value;
            break;
          case "Estimated Year":
            parsed_info.estimatedYear = value;
            break;
          case "Specifications":
          case "Features":
          case "Compatibility":
          case "Maintenance":
            currentSection = key.toLowerCase();
            break;
          default:
            if (currentSection === "specifications") {
              parsed_info.specifications[key] = value;
            }
        }
      } else if (line.startsWith("-") && currentSection) {
        const value = line.substring(1).trim();
        if (currentSection === "features") {
          parsed_info.features.push(value);
        } else if (currentSection === "maintenance") {
          parsed_info.maintenance.push(value);
        }
      } else if (line && currentSection === "compatibility") {
        parsed_info.compatibility += line.trim() + " ";
      }
    });

    parsed_info.compatibility = parsed_info.compatibility.trim();

    return {
      success,
      inferred_info,
      accuracy_level,
      accuracy_enum,
      manual_input,
      parsed_info,
    };
  }

  extractEquipmentInfoWithExtras(data: any): EquipmentInfo {
    const extractedInfo = data.extracted_info || {};
    const result: EquipmentInfo = {
      manufacturer: "",
      model: "",
      yearOfManufacture: null,
      serialNumber: "",
    };

    const findValue = (keys: string[]): string => {
      for (const key of keys) {
        const foundKey = Object.keys(extractedInfo).find((k) =>
          k
            .toLowerCase()
            .replace(/[^a-z0-9]/g, "")
            .includes(key.toLowerCase())
        );
        if (foundKey) {
          return extractedInfo[foundKey].replace(/^\*\*|\*\*$/g, "").trim();
        }
      }
      return "";
    };

    // Extract specific fields
    result.manufacturer = findValue(["manufacturer"]);
    result.model = findValue(["gensetmodel", "modelnumber", "model"]);
    result.serialNumber = findValue(["serialnumber", "serialno"]);

    const manufacturingDate = findValue(["manufacturingdate", "mfgdate"]);
    if (manufacturingDate) {
      const match = manufacturingDate.match(/\d{4}/);
      result.yearOfManufacture = match ? match[0] : null;
    }

    // Extract all other fields
    for (const [key, value] of Object.entries(extractedInfo)) {
      const cleanKey = key.replace(/^[-\s*]+|[-\s*]+$/g, "").trim();
      const camelCaseKey = cleanKey
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
          if (+match === 0) return ""; // remove spaces
          return index === 0 ? match.toLowerCase() : match.toUpperCase();
        })
        .replace(/[^a-zA-Z0-9]/g, "");

      const cleanValue = typeof value === "string" ? value.replace(/^\*\*|\*\*$/g, "").trim() : value;

      if (!Object.keys(result).includes(camelCaseKey)) {
        result[camelCaseKey] = cleanValue;
      }
    }

    return result;
  }

  getSystemMeasurementUnit(systemType: SystemTypeEnum): string | undefined {
    const systemConfirmationResponse = this.getSystemConfirmationResponse();
    console.log("System response:", localStorage.getItem("systemConfirmationResponse"));

    if (!systemConfirmationResponse) {
      console.warn("No system confirmation response available");
      return undefined;
    }

    switch (systemType) {
      case SystemTypeEnum.Engine:
        return systemConfirmationResponse.systemTypeId === 13
          ? UNIT_OF_MEASUREMENTS_ENGINE.INBOARD
          : UNIT_OF_MEASUREMENTS_ENGINE.OUTBOARD;
      case SystemTypeEnum.Stabilization:
        return systemConfirmationResponse.systemTypeId === 16
          ? UNIT_OF_MEASUREMENTS_STABILIZER.GYRO
          : UNIT_OF_MEASUREMENTS_STABILIZER.FINS;
      default:
        console.warn(`Unsupported system type: ${systemType}`);
        return undefined;
    }
  }

  getSystemImage() {
    return this.systemImageSubject.value;
  }

  setSystemImage(image: string) {
    this.systemImageSubject.next(image);
  }

  getCompatibleParts() {
    return this.compatiblePartsSubject.value;
  }

  setCompatibleParts(parts: any) {
    this.compatiblePartsSubject.next(parts);
  }

  getSelectedPart() {
    return this.selectedPartSubject.value;
  }

  setSelectedPart(part: any) {
    this.selectedPartSubject.next(part);
  }

  getShopParts() {
    return this.shopPartsSubject.value;
  }

  setShopParts(parts: any) {
    this.shopPartsSubject.next(parts);
  }

  getAllSystemParts(systemId: number): Observable<any> {
    return this.httpClient.get(`${this.env.apiUrl}services/app/PartKnowledgeBase/GetParts?partType=${systemId}`);
  }

  getComponentDetails(yachtId: number, systemTypeId: SystemTypeEnum): Observable<any> {
    const url = `${this.env.apiUrl}services/app/Components/GetComponentDetails`;

    // Create HttpParams object with the query parameters
    const params = new HttpParams().set("yachtId", yachtId).set("systemType", systemTypeId);

    // Make the HTTP GET request
    return this.httpClient.get(url, { params });
  }

  getComponentName(partType: SymptomPartTypeEnum): string {
    switch (partType) {
      case ComponentTypeConst.GENERAL_ENGINE:
        return "Engine";
      case ComponentTypeConst.OUTBOARD_ENGINE:
        return "Outboard Engine";
      case ComponentTypeConst.INBOARD_ENGINE:
        return "Inboard Engine";
      case ComponentTypeConst.GENERATOR:
        return "Generator";
      case ComponentTypeConst.GENERAL_STABILIZER:
        return "Stabilizer";
      case ComponentTypeConst.FIN_STABILIZER:
        return "Fin Stabilizer";
      case ComponentTypeConst.GYRO_STABILIZER:
        return "Gyro Stabilizer";
      case ComponentTypeConst.AIR_CONDITIONER:
        return "AC / Heat";
      case ComponentTypeConst.WATER_MAKER:
        return "Water Maker";
      case ComponentTypeConst.REFRIGERATOR:
        return "Refrigerator";
      case ComponentTypeConst.ICE_MAKER:
        return "Ice Maker";
      case ComponentTypeConst.TOILET:
        return "Vacuum Toilet";
      case ComponentTypeConst.ZINC:
        return "Zincs";
      default:
        return "Custom Component";
    }
  }

  getAIAPISystemType(partType: SymptomPartTypeEnum): string {
    const numericalSystemTypeId = Number(partType);
    switch (numericalSystemTypeId) {
      case ComponentTypeConst.GENERAL_ENGINE:
        return "engine";
      case ComponentTypeConst.OUTBOARD_ENGINE:
        return "engine";
      case ComponentTypeConst.INBOARD_ENGINE:
        return "engine";
      case ComponentTypeConst.GENERATOR:
        return "generator";
      case ComponentTypeConst.GENERAL_STABILIZER:
        return "stabilizer";
      case ComponentTypeConst.FIN_STABILIZER:
        return "stabilizer";
      case ComponentTypeConst.GYRO_STABILIZER:
        return "stabilizer";
      case ComponentTypeConst.AIR_CONDITIONER:
        return "air conditioner";
      case ComponentTypeConst.WATER_MAKER:
        return "watermaker";
      case ComponentTypeConst.REFRIGERATOR:
        return "refrigerator";
      case ComponentTypeConst.ICE_MAKER:
        return "icemaker";
      case ComponentTypeConst.TOILET:
        return "toilet";
      case ComponentTypeConst.ZINC:
        return "zincs";
      default:
        return "custom component";
    }
  }

  //Captain AI Part Finder

  postPartDetails(partDetails: partFinderPayload | any): Observable<any> {
    return this.httpClient.post<any>("https://captainai.boatbot.ai/partfinder", partDetails);
  }

  postPartFinderImage(partDetails: partFinderPayload | any): Observable<any> {
    return this.httpClient.post<any>("https://captainai.boatbot.ai/partfinder/image", partDetails);
  }
  postPartFinderManual(partDetails: partFinderPayload | any): Observable<any> {
    return this.httpClient.post<any>("https://captainai.boatbot.ai/partfinder/manual", partDetails);
  }

  getSystemDetails(systemDetails: partFinderPayload | any): Observable<any> {
    return this.httpClient.post<any>("https://captainai.boatbot.ai/systemfinder", systemDetails);
  }

  shopParts(compatible_parts: compatible_parts, max_results = 10): Observable<any> {
    const params = new HttpParams().set("max_results", max_results?.toString());

    return this.httpClient.post<any>("https://captainai.boatbot.ai/partfinder/shop", compatible_parts, { params });
  }
}

export interface compatible_parts {
  system_type: string;
  manufacturer: string;
  model: string;
  product_type: string;
}

export interface SystemInfo {
  system_type: string;
  manufacturer: string;
  part_type: string | null;
  model_number: string;
  serial_number: string;
  specifications: {
    [key: string]: string;
  };
}

export interface PartsInfo {
  success: boolean;
  inferred_info: string;
  accuracy_level: number;
  accuracy_enum: {
    NO_MATCH: number;
    PARTIAL_MATCH: number;
    MOSTLY_ACCURATE: number;
    FULLY_ACCURATE: number;
  };
  manual_input: string;
  parsed_info: {
    manufacturer: string;
    model: string;
    serialNumber: string;
    estimatedYear: string;
    specifications: { [key: string]: string };
    features: string[];
    compatibility: string;
    maintenance: string[];
  };
}

export interface Info {
  success: boolean;
  extracted_info: { [key: string]: string };
  chatgpt_analysis: string;
  parsed_info: {
    manufacturer: string;
    gensetModel: string;
    specNumber: string;
    serialNumber: string;
    materialNumber: string;
    serviceDuty: string;
    voltage: string;
    phase: string;
    frequency: string;
    amps: string;
    kw: string;
    kva: string;
    rpm: string;
    powerFactor: string;
    battery: string;
    fuelType: string;
    manufactureDate: string;
  };
}

export interface EquipmentInfo {
  manufacturer: string;
  model: string;
  yearOfManufacture: string | null;
  serialNumber: string;
  [key: string]: any; // Allow for additional properties
}

export interface MainParts {
  manufacturer: string;
  model_number: string;
  serial_number: string;
  year_of_manufacture: string;
}
export interface JsonInput {
  success: boolean;
  inferred_info: string;
  accuracy_level: number;
  accuracy_enum: {
    NO_MATCH: number;
    PARTIAL_MATCH: number;
    MOSTLY_ACCURATE: number;
    FULLY_ACCURATE: number;
  };
  manual_input: string;
}

export interface InferredInfo {
  [key: string]: string | string[];
}

export type PartFinderSystemData = {
  yachtId?: number;
  systemTypeId: number;
  systemName: string;
  componentTypeId?: number;
};

export class ComponentTypeConst {
  public static GENERAL_ENGINE = 1;
  public static GENERAL_STABILIZER = 2;
  public static WATER_MAKER = 3;
  public static GENERATOR = 4;
  public static ICE_MAKER = 8;
  public static TOILET = 9;
  public static ZINC = 10;
  public static AIR_CONDITIONER = 11;
  public static REFRIGERATOR = 12;

  public static GYRO_STABILIZER = 16;
  public static FIN_STABILIZER = 15;

  public static OUTBOARD_ENGINE = 14;
  public static INBOARD_ENGINE = 13;

  public static getIdForApi(input: SymptomPartTypeEnum): SymptomPartTypeEnum {
    if (input == this.GYRO_STABILIZER || input == this.FIN_STABILIZER) return this.GENERAL_STABILIZER;

    if (input == this.INBOARD_ENGINE || input == this.OUTBOARD_ENGINE) return this.GENERAL_ENGINE;

    return input;
  }

  public static mapPartTypeToSystemType(partType: SymptomPartTypeEnum): SystemTypeEnum {
    switch (partType) {
      case this.GENERAL_ENGINE:
      case this.INBOARD_ENGINE:
      case this.OUTBOARD_ENGINE:
        return SystemTypeEnum.Engine;
      case this.GENERATOR:
        return SystemTypeEnum.Generator;
      case this.GENERAL_STABILIZER:
      case this.GYRO_STABILIZER:
      case this.FIN_STABILIZER:
        return SystemTypeEnum.Stabilization;
      case this.AIR_CONDITIONER:
        return SystemTypeEnum.AirHeat;
      case this.WATER_MAKER:
        return SystemTypeEnum.WaterMaker;
      case this.REFRIGERATOR:
        return SystemTypeEnum.Refrigerator;
      case this.ICE_MAKER:
        return SystemTypeEnum.IceMaker;
      case this.TOILET:
        return SystemTypeEnum.Toilet;
      case this.ZINC:
        return SystemTypeEnum.Zincs;
      default:
        return SystemTypeEnum.CustomComponent;
    }
  }

  public static mapSystemTypeToPartType(systemType: SystemTypeEnum): SymptomPartTypeEnum {
    switch (systemType) {
      case SystemTypeEnum.Engine:
        return this.GENERAL_ENGINE;
      case SystemTypeEnum.Generator:
        return this.GENERATOR;
      case SystemTypeEnum.Stabilization:
        return this.GENERAL_STABILIZER;
      case SystemTypeEnum.AirHeat:
        return this.AIR_CONDITIONER;
      case SystemTypeEnum.WaterMaker:
        return this.WATER_MAKER;
      case SystemTypeEnum.Refrigerator:
        return this.REFRIGERATOR;
      case SystemTypeEnum.IceMaker:
        return this.ICE_MAKER;
      case SystemTypeEnum.Toilet:
        return this.TOILET;
      case SystemTypeEnum.Zincs:
        return this.ZINC;
      default:
        return this.GENERAL_ENGINE;
    }
  }

  public static determineSystemModalData(partType?: SymptomPartTypeEnum) {
    const lang = localStorage.getItem("lang") || "en";
    switch (partType) {
      case ComponentTypeConst.GENERAL_ENGINE:
      case ComponentTypeConst.INBOARD_ENGINE:
        return {
          title: lang == "en" ? "Locate Engine Serial Number" : "Localizar Número de Serie del Motor",
          description:
            lang == "en"
              ? "Locate your engine's serial number. This is usually located on the Identification Plate of the engine. The Identification Plate is mainly located on the Starboard or Port side of the engine.Typically located above the lower assembly."
              : "Localice el número de serie de su motor. Por lo general, se encuentra en la placa de identificación del motor. La placa de identificación se encuentra principalmente en el lado de estribor o babor del motor. Por lo general, ubicado encima del ensamblaje inferior.",
          image: "assets/images/systems/inboard-engine.png",
        };
      case ComponentTypeConst.OUTBOARD_ENGINE:
        return {
          title: lang == "en" ? "Locate Engine Serial Number" : "Localizar Número de Serie del Motor",
          description:
            lang == "en"
              ? "Locate your engine's serial number. This is usually located on the Identification Plate of the engine. The Identification Plate is mainly located on the Starboard or Port side of the engine.Typically located above the lower assembly."
              : "Localice el número de serie de su motor. Por lo general, se encuentra en la placa de identificación del motor. La placa de identificación se encuentra principalmente en el lado de estribor o babor del motor. Por lo general, ubicado encima del ensamblaje inferior.",
          image: "assets/images/systems/engine-outboard.jpg",
        };
      case ComponentTypeConst.GENERATOR:
        return {
          title: lang == "en" ? "Locate Generator Serial Number" : "Localizar Número de Serie del Generador",
          description:
            lang == "en"
              ? "Locate your generator's serial number. This is usually located on the Identification Plate of the generator. The Identification Plate is mainly located on the service side of your generator. Other location could be the base of the generator."
              : "Localice el número de serie de su generador. Normalmente se encuentra en la placa de identificación del generador. La placa de identificación se encuentra principalmente en el lado de servicio de su generador. Otra ubicación podría ser la base del generador.",
          image: "assets/images/systems/generator.jpg",
        };
      case ComponentTypeConst.GENERAL_STABILIZER:
      case ComponentTypeConst.GYRO_STABILIZER:
      case ComponentTypeConst.FIN_STABILIZER:
        return {
          title:
            lang == "en"
              ? "Locate Stabilizer - Fin Serial Number"
              : "Localizar Número de Serie del Estabilizador - Aleta",
          description:
            lang == "en"
              ? "Locate your stabilizer - fin's serial number. This is usually located on the Identification Plate of the stabilizer - fin. The Identification Plate is mainly located on the inside unite on top of the control panel."
              : "Localice el número de serie de su estabilizador - aleta. Normalmente se encuentra en la placa de identificación del estabilizador - aleta. La placa de identificación se encuentra principalmente en la parte interior unida en la parte superior del panel de control.",
          image: "assets/images/systems/stabilizer-fin.jpg",
        };
      case ComponentTypeConst.AIR_CONDITIONER:
        return {
          title:
            lang == "en"
              ? "Locate AC / Heat Serial Number"
              : "Localizar Número de Serie del Aire Acondicionado / Calefacción",
          description:
            lang == "en"
              ? "Locate your AC / Heat serial number. This is usually located on the Identification Plate of the AC / Heat."
              : "Localice el número de serie de su AC / Heat. Normalmente se encuentra en la placa de identificación del AC / Heat.",
        };
      case ComponentTypeConst.WATER_MAKER:
        return {
          title:
            lang == "en" ? "Locate Water Maker Serial Number" : "Localizar Número de Serie del Potabilizador de Agua",
          description:
            lang == "en"
              ? "Locate your water maker's serial number. This is usually located on the Identification Plate of the water maker."
              : "Localice el número de serie de su potabilizador de agua. Normalmente se encuentra en la placa de identificación del potabilizador de agua.",
        };
      case ComponentTypeConst.REFRIGERATOR:
        return {
          title: lang == "en" ? "Locate Refrigerator Serial Number" : "Localizar Número de Serie del Refrigerador",
          description:
            lang == "en"
              ? "Locate your refrigerator's serial number. This is usually located on the Identification Plate of the refrigerator. The Identification Plate is mainly located on the inside of the door or on the inside side wall of the unit."
              : "Localice el número de serie de su refrigerador. Normalmente se encuentra en la placa de identificación del refrigerador. La placa de identificación se encuentra principalmente en el interior de la puerta o en la pared lateral interior de la unidad.",
          image: "assets/images/systems/refrigerator.jpg",
        };
      case ComponentTypeConst.ICE_MAKER:
        return {
          title: lang == "en" ? "Locate Ice Maker Serial Number" : "Localizar Número de Serie de la Máquina de Hielo",
          description:
            lang == "en"
              ? "Locate your ice maker's serial number. This is usually located on the Identification Plate of the ice maker."
              : "Localice el número de serie de su máquina de hielo. Normalmente se encuentra en la placa de identificación de la máquina de hielo.",
          image: "assets/images/systems/icemaker.jpg",
        };
      case ComponentTypeConst.TOILET:
        return {
          title: lang == "en" ? "Locate Vacum Toilet Serial Number" : "Localizar Número de Serie del Inodoro de Vacío",
          description:
            lang == "en"
              ? "Locate your vacum toilet's serial number. This is usually located on the Identification Plate of the vacum toilet."
              : "Localice el número de serie de su inodoro de vacío. Normalmente se encuentra en la placa de identificación del inodoro de vacío.",
          image: "assets/images/systems/toilet.jpg",
        };
      case ComponentTypeConst.ZINC:
        return {
          title: lang == "en" ? "Locate Zincs Serial Number" : "Localizar Número de Serie de los Zincs",
          description:
            lang == "en"
              ? "Locate your zincs's serial number. This is usually located on the Identification Plate of the zincs."
              : "Localice el número de serie de sus zincs. Normalmente se encuentra en la placa de identificación de los zincs.",
        };
      default:
        return {
          title: lang == "en" ? "Locate Serial Number" : "Localizar Número de Serie",
          description:
            lang == "en"
              ? "Locate your system's serial number. This is usually located on the Identification Plate of the system."
              : "Localice el número de serie de su sistema. Por lo general, se encuentra en la placa de identificación del sistema.",
        };
    }
  }
}

export interface IIdentificationProcess {
  yachtId: number;
  component: IComponentDto;
  processMeta: IIdentificationProcessMeta;
}

export enum IdentificationProcessTypeEnum {
  SYSTEM_IDENTIFY = "system_identify",
  PART_FINDER = "part_finder",
}

export interface IIdentificationProcessMeta {
  processType: IdentificationProcessTypeEnum;
  destinationUrl: string;
  originUrl?: string;
}
