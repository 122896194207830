import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";

@Component({
  selector: "app-locate-serial-number",
  templateUrl: "./locate-serial-number.component.html",
  styleUrls: ["./locate-serial-number.component.css"],
})
export class LocateSerialNumberComponent implements OnInit {
  @Input() title = "Locate Serial Number";
  @Input() description =
    "Locate your system's serial number. This is usually located on the Identification Plate of the system.";
  @Input() image: any = null;
  @Input() data: any;
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    if (this.data) {
      this.title = this.data.title;
      this.description = this.data.description;
      this.image = this.data.image;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
