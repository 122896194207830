import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { BaseService } from "../shared/base.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { EnvironmentService } from "../environment.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  public modal = null;
  public jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private baseService: BaseService,
    private router: Router,
    private env: EnvironmentService,
 
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

 

     
    if (
      this.baseService.getToken() &&
      !this.jwtHelper.isTokenExpired(this.baseService.getToken())
    ) {
      console.log("AuthGuard active", route.url);
      this.baseService.authenticated = true;
    } else {
      console.log("AuthGuard");
      this.baseService.authenticated = false;
      this.baseService.clearSessionData();
      if (
        !this.baseService.checkIsNotFirstTimer() &&
        !localStorage.getItem("isPreviousLogin")
      ) {
        if (environment.buildType === "boatowner") {
          const captainDataString = localStorage.getItem("captainAiData");

          if (captainDataString) {
            const captainAiData = JSON.parse(captainDataString);
            if (captainAiData?.firstName) {
              this.router.navigate(["/captain-talk/authentication/dashboard"]);
            }
          } else {
            this.router.navigate(["/captain-talk/authentication/welcome"]);
          }
        } else {
          this.router.navigate(["/captain-talk/authentication/signin"]);
        }
      } else {
        if (!this.baseService.getSessionData()) {
          this.router.navigate(["/captain-talk/authentication/signin"]);
        }
      }
    }
    return true;
  }
}
