import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ApplicationKeys {
  googleApiKey: string;
}

@Injectable({
  providedIn: 'root'
})

export class AppInitService {
 private awsCredentials:any;
  private applicationKeys: ApplicationKeys;
  constructor(
    private injector: Injector,
  ) { }

  public init() {
    const http = this.injector.get(HttpClient);

    // const awsCredentialsCall = http.get(`${environment.apiUrl}services/app/Utility/GetAwsCredentials`);
    const applicationKeysCall = http.get(`${environment.apiUrl}services/app/Utility/GetApplicationKeys`);

    // return forkJoin([awsCredentialsCall, applicationKeysCall])
    //   .toPromise()
    //   .then(([awsCredentialsData, applicationKeysData]: any) => {
    //     console.log('AppInitService1', awsCredentialsData);
    //     console.log('AppInitService2', applicationKeysData);
    //     this.setAwsCredentials(awsCredentialsData.result);
    //     this.setApplicationKeys(applicationKeysData.result);
    //   })
    //   .catch((err) => {
    //     console.error('Error during initialization:', err);
    //     // Handle the error gracefully (e.g., fallback values or retry logic)
    //   });

    // awsCredentialsCall.subscribe((awsCredentialsData: any) => {
    //   console.log('AppInitService1', awsCredentialsData);
    //   this.setAwsCredentials(awsCredentialsData.result);
    // },err=>{
    //   console.error('Error during initialization:', err);
    // })

    applicationKeysCall.subscribe((applicationKeysData: any) => {
      this.setApplicationKeys(applicationKeysData.result);
    },err=>{
      console.error('Error during initialization:', err);
    })
  }

  getAwsCredentials(){
    return this.awsCredentials;
  }

  getApplicationKeys(){
    return this.applicationKeys;
  }

  setAwsCredentials(awsCredentials){
    this.awsCredentials = awsCredentials;
  }

  setApplicationKeys(applicationKeys:ApplicationKeys){
    this.applicationKeys = applicationKeys;
    this.injectGoogleMapsScript(applicationKeys.googleApiKey);

    // Check if google maps is loaded
    setTimeout(() => {
      this.waitForGoogleMapsToLoad().then(() => {
        console.warn('Google Maps API loaded');
      });

    }, 5000);

  }

  waitForGoogleMapsToLoad(): Promise<void> {
    return new Promise((resolve) => {
      if (typeof google !== 'undefined') {
        resolve();
      } else {
        const interval = setInterval(() => {
          if (typeof google !== 'undefined') {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      }
    });
  }

  injectGoogleMapsScript(apiKey: string) {
    if (document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]')) {
      console.warn('Google Maps script is already loaded.');
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

}