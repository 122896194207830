<ion-app mode="ios" >
    <div
        class="flex flex-wrap row content-center"
        *ngIf="!networkStatusChangeValue"
    >
        <ng-container>
            <div class="w-full p-4 z-[99999999] fixed top-0 shadow-sm">
                <div
                    class="bg-dsError font-bold text-white text-center flex w-full items-center p-4 py-3 rounded-lg text-base"
                >
                    <img
                        class="text-white w-8 h-8"
                        src="assets/svgs/wifi-off-svgrepo-com.svg"
                    />

                    <label class="ml-3">No internet connection.</label>
                </div>
            </div>
        </ng-container>
    </div>

    <app-camera
        style="position: absolute; z-index: 99999; width: 100%"
    ></app-camera>

    <ion-modal [isOpen]="isUpdateAvailable" [backdropDismiss]="false" [canDismiss]="false" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
        <ng-template>

          
          <div class="flex flex-col items-center justify-center w-full h-[300px] p-4">
            
          
            <div class="flex justify-center flex-col" >
                <div class="m-auto">
                    <img
                    src="assets/captain-talk/captain-talk.svg"
                    alt="captain ai logo"
                    slot="end"
                    height="96"
                    width="96"
                    class="ml-5"
                  />
                </div>
               
                <h1 class="text-center text-brandDeepSeaBlue font-extrabold text-xl mt-2">NEW UPDATE!</h1>
                <p class="text-center text-[#1F1F1F] text-base font-medium tracking-wide">
                  <span >
                    There is a new update available. Please click the button below to update the app.
                  </span>
                 
                </p>
            
               
            
                <ion-button (click)="updateApp()" expand="block" shape="round" class="my-5 font-bold h-14">
                  <span class="text-xl font-bold">Update Now</span>
                </ion-button>
              </div>

          </div>
        </ng-template>
      </ion-modal>

    <ion-router-outlet [ngClass]="{'boxed-layout-container': platform_mode === 'desktop'}"></ion-router-outlet>
    <app-loader [isSlowInternet]="isSlowInternet"></app-loader>
</ion-app>
