export const VESSEL_TYPES = {
    POWER: 'power',
    SAIL: 'sail'
}

export const UNIT_OF_MEASUREMENTS = {
    FEET: 'feet',
    METRES: 'meters'
}

export const UNIT_OF_MEASUREMENTS_ENGINE = {
    INBOARD: 'inboard',
    OUTBOARD: 'outboard'
}

export const UNIT_OF_MEASUREMENTS_STABILIZER = {
    GYRO: 'gyro',
    FINS: 'fins'
}
