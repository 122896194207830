import { Injectable } from "@angular/core";
import { BoatManagementService } from "../boat-management.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  GalleryImageDto,
  IDefaultYachtDtoInput,
  IGalleryImageDto,
  IGetMyGalleryImagesOutput,
  IGetVesselInformation,
  YachtVitalsServiceProxy,
} from "src/shared/service-proxies/service-proxies";
import { YachtsServiceProxy } from "src/shared/service-proxies/service-proxies";
import {
  IFilesData,
  IImageBrowserConfig,
  ILightboxImage,
  ImageBrowserService,
} from "src/app/shared/image-management/services/image-browser.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { ToastService } from "src/app/shared/message/toast.service-impl";

@Injectable({
  providedIn: "root",
})
export class BoatProfileService {
  private currentBoatSubject = new BehaviorSubject<IGetVesselInformation>(null);
  public currentBoat$ = this.currentBoatSubject.asObservable();

  private isBoatProfileLoadingSubject = new BehaviorSubject<boolean>(false);

  private currentBoatImagesSubject = new BehaviorSubject<IGetMyGalleryImagesOutput>({
    totalCount: 0,
    items: [],
    primaryImage: null,
  });
  public currentBoatImages$ = this.currentBoatImagesSubject.asObservable();
  protected readonly LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  private reloadSummaryComponentsSubject = new BehaviorSubject<boolean>(false);
  public reloadSummaryComponents$ = this.reloadSummaryComponentsSubject.asObservable();

  private reloadSummaryVesselInformationSubject = new BehaviorSubject<boolean>(false);
  public reloadSummaryVesselInformation$ = this.reloadSummaryVesselInformationSubject.asObservable();

  constructor(
    private boatManagementService: BoatManagementService,
    private yachtServiceProxy: YachtsServiceProxy,
    private toastService: ToastService,
    private yachtVitalsServiceProxy: YachtVitalsServiceProxy,

    private boatProfileService: BoatProfileService,
    private imageBrowserService: ImageBrowserService
  ) {}

  reloadSummary(): void {
    this.reloadSummaryComponentsSubject.next(true);
  }

  reloadSummaryVesselInformation(): void {
    this.reloadSummaryVesselInformationSubject.next(true);
  }

  async changeBoat(boatId: number, setAsDefault = true): Promise<IGetVesselInformation> {
    return new Promise<IGetVesselInformation>((resolve, reject) => {
      this.isBoatProfileLoadingSubject.next(true);
      const setDefaultBoatPromise = setAsDefault ? this.setDefaultBoat(boatId) : Promise.resolve();

      setDefaultBoatPromise
        .then(() => {
          this.boatManagementService.getBoatById(boatId).subscribe({
            next: (boat) => {
              console.log("Boat changed to:", boat);
              localStorage.setItem("currentYacht", JSON.stringify(boat));
              if (!boat) {
                this.isBoatProfileLoadingSubject.next(false);
                resolve(null);
              }

              this.initializeBoatProfile(boat);

              this.isBoatProfileLoadingSubject.next(false);

              resolve(boat);
            },
            error: (err) => {
              this.isBoatProfileLoadingSubject.next(false);
              console.error(err);
              reject();
            },
          });
        })
        .catch((err) => {
          this.isBoatProfileLoadingSubject.next(false);
          console.error(err);
          reject();
        });
    });
  }

  updateBoatImageUrl(imageUrl: string): void {
    if (!this.currentBoatSubject.value) {
      return;
    }

    const currentBoat = this.currentBoatSubject.value;
    currentBoat.imageUrl = imageUrl;

    this.setCurrentBoat(currentBoat);
  }

  public reloadCurrentBoat(): Promise<IGetVesselInformation> {
    return new Promise<IGetVesselInformation>((resolve, reject) => {
      this.boatManagementService.getBoatById(this.currentBoatSubject.value.yachtId).subscribe({
        next: (boat) => {
          this.initializeBoatProfile(boat);
          resolve(boat);
        },
        error: (err) => {
          console.error(err);
          reject();
        },
      });
    });
  }

  async getCurrentBoat(): Promise<IGetVesselInformation> {
    console.log("Getting current boat");
    if (this.isBoatProfileLoadingSubject.value) {
      return new Promise<IGetVesselInformation>((resolve, reject) => {
        this.currentBoat$.subscribe((boat) => {
          resolve(boat);
        });
      });
    }
    return new Promise<IGetVesselInformation>((resolve, reject) => {
      const boat = this.currentBoatSubject.value;
      if (!boat) {
        this.isBoatProfileLoadingSubject.next(true);
        this.boatManagementService.getDefaultBoat().subscribe({
          next: (boat) => {
            if (!boat) {
              this.isBoatProfileLoadingSubject.next(false);
              resolve(null);
            }

            this.initializeBoatProfile(boat);

            this.isBoatProfileLoadingSubject.next(false);

            resolve(boat);
          },
          error: (err) => {
            this.isBoatProfileLoadingSubject.next(false);
            console.error(err);
            resolve(null);
          },
        });
      } else {
        resolve(boat);
      }
    });
  }

  initializeBoatProfile(boat: IGetVesselInformation): void {
    this.setCurrentBoat(boat);
    console.log(this.currentBoatSubject.value, boat);

    this.getBoatImages({ boatId: boat.yachtId })
      .then((res) => {
        this.setCurrentBoatImages(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getBoatImages(filterOptions: IGetBoatGalleryFilterOptions): Promise<IGetMyGalleryImagesOutput> {
    return new Promise<IGetMyGalleryImagesOutput>((resolve, reject) => {
      this.fetchBoatImages(filterOptions).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (err) => {
          console.error(err);
          reject(err);
        },
      });
    });
  }

  async loadCurrentBoatImages(optimisticData?: {
    items?: GalleryImageDto[];
    totalCount?: number;
    primaryImage?: GalleryImageDto;
  }): Promise<void> {
    const currentBoat = this.currentBoatSubject.value;
    if (!currentBoat) {
      return null;
    }
    this.getBoatImages({ boatId: this.currentBoatSubject.value.yachtId }).then((res) => {
      const updatedImages: IGetMyGalleryImagesOutput = {
        items: optimisticData?.items ?? res.items,
        totalCount: optimisticData?.totalCount ?? res.totalCount,
        primaryImage: optimisticData?.primaryImage ?? res.primaryImage,
      };
      this.setCurrentBoatImages(updatedImages);
    });
  }

  setDefaultBoat(boatId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload: IDefaultYachtDtoInput = { yachtId: boatId };
      this.boatManagementService.setDefaultBoat(payload).subscribe({
        next: () => {
          console.log(`Default boat set to ID: ${boatId}`);
          resolve();
        },
        error: (err) => {
          console.error("Failed to set default boat:", err);
          reject(err);
        },
      });
    });
  }

  setCurrentBoat(boat: IGetVesselInformation): void {
    this.currentBoatSubject.next(boat);
  }

  setCurrentBoatImages(images: IGetMyGalleryImagesOutput): void {
    this.currentBoatImagesSubject.next(images);
  }

  fetchBoatImages(filterOptions: IGetBoatGalleryFilterOptions): Observable<IGetMyGalleryImagesOutput> {
    const defaultFilterOptions = {
      isFavorite: false,
      skipCount: 0,
      maxResultCount: 10,
    };

    return this.yachtServiceProxy.getMyGalleryImages(
      filterOptions.isFavorite ?? defaultFilterOptions.isFavorite,
      filterOptions.boatId,
      filterOptions.skipCount ?? defaultFilterOptions.skipCount,
      filterOptions.maxResultCount ?? defaultFilterOptions.maxResultCount
    );
  }

  // async handleGallery(boatId: number, galleryImages: any) {
  //   const imageBrowserConfig: IImageBrowserConfig = {
  //     enableRemove: true,
  //     enableAdd: true,
  //     enableSetPrimary: true,
  //     enableCrop: true,
  //     textConfig: {
  //       title: this.LANGUAGE_DATA?.DASHBOARD.IMAGE_BROWSER_TITLE,
  //     },
  //   };
  //
  //   const images = this.prepareGalleryImages(galleryImages?.items);
  //   const yachtId = boatId;
  //
  //   const modal = await this.imageBrowserService.openImageBrowser(imageBrowserConfig, images);
  //   modal.present();
  //
  //   const filterOptions = { boatId: yachtId };
  //   this.boatProfileService.getBoatImages(filterOptions).then((res) => {
  //     const images = this.prepareGalleryImages(res.items);
  //     modal.componentProps["imagesUpdated$"].next(images);
  //   });
  //
  //   modal.componentProps["removeImage$"].subscribe(async (result: { image: ILightboxImage; index: number }) => {
  //     await this.imageBrowserService.removeBoatImage(result.image);
  //     modal.componentProps["imagesRemoved$"].next([result.image]);
  //     await this.boatProfileService.loadCurrentBoatImages();
  //
  //     this.toastService.showSuccessMessage("Image removed successfully");
  //   });
  //
  //   modal.componentProps["addImage$"].subscribe(async (imageData: IFilesData[]) => {
  //     const optimisticTotalCount = (galleryImages.totalCount += imageData.length);
  //     this.imageBrowserService.addBoatImages(imageData, yachtId).then(async (clientIds) => {
  //       modal.componentProps["imagesAdded$"].next(clientIds);
  //
  //       await this.boatProfileService.loadCurrentBoatImages({
  //         items: galleryImages.items as GalleryImageDto[],
  //         totalCount: optimisticTotalCount,
  //       });
  //
  //       const message = imageData.length > 1 ? "Images added successfully" : "Image added successfully";
  //       this.toastService.showSuccessMessage(message);
  //     });
  //   });
  //
  //   modal.componentProps["setPrimary$"].subscribe(async (result: { image: ILightboxImage; index: number }) => {
  //     const response = await this.boatManagementService.setBoatPrimaryImage(yachtId, result?.image?.clientId);
  //     if (response) {
  //       // this.coverImageConfig.coverImageSrc = result.image.imageUrl;
  //
  //       this.boatProfileService.updateBoatImageUrl(result.image.imageUrl);
  //       await this.boatProfileService.loadCurrentBoatImages();
  //
  //       this.toastService.showSuccessMessage("Primary image set successfully");
  //     }
  //   });
  //
  //   modal.componentProps["loadMore$"].subscribe(() => {
  //     this.imageBrowserService.loadMoreBoatImages(yachtId);
  //   });
  //
  //   const { data } = await modal.onWillDismiss();
  //
  //   galleryImages.items = data;
  //   if (data) {
  //     const newCoverImage = data.find((image) => image.isPrimary);
  //     if (newCoverImage) {
  //       // this.coverImageConfig.coverImageSrc =
  //       //   newCoverImage?.imageUrl ?? newCoverImage?.image ?? data[0]?.imageUrl ?? data[0]?.image;
  //     }
  //   }
  // }

  prepareGalleryImages(images: GalleryImageDto[] | ILightboxImage[]): ILightboxImage[] {
    return images.map((image) => ({
      imageUrl: image.imageUrl,
      isPrimary: image.isPrimary,
      clientId: image.clientId,
      uploaded: image.uploaded ?? true,
      image: image.image,
    }));
  }
}

export interface IGetBoatGalleryFilterOptions {
  isFavorite?: boolean;
  boatId: number;
  skipCount?: number;
  maxResultCount?: number;
}
