import { YachtMessagesService } from './shared/messages.service';
import { BuildType } from './../environments/ienvironment';
import { Component, ComponentRef, NgZone, OnInit } from '@angular/core';
import { BaseService } from './shared/base.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Spinkit } from 'ng-http-loader';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import {
	AlertController,
	LoadingController,
	ModalController,
	NavController,
	Platform,
	PopoverController,
	ToastController,
	isPlatform,
} from '@ionic/angular';
import { NetworkStatusCheckService } from './shared/network-status-check.service';
import { InAppService } from './shared/in-app/in-app-service';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Meta } from '@angular/platform-browser';
import { UtilityService } from './shared/utility.service';
import { NavigationExtras, Router } from '@angular/router';
import { TextZoom } from '@capacitor/text-zoom';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
import {
	combineLatest,
	timer,
	retry,
	throwError,
	Subscription,
	from,
} from 'rxjs';
import { catchError, delay, finalize, take, tap } from 'rxjs/operators';
import { LoaderService } from './shared/loader/loader.service';
import { AccountSettingService } from './yacht-owner/account-settings/account-setting.service';
import { DataService } from './services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from './shared/message/toast.service-impl';
import { SignalrService } from './yacht-owner/signalr.service';
import { AudioService } from './captain-talk/services/audio.service';
import { PushNotificationService } from './shared/push-notification.service';
import { FacebookTrackingService } from './services/facebook-tracking.service';
import { DATA_TYPE } from 'src/app/shared/push-notification.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { EstimateDetailComponent } from './yacht-owner/finance/estimate/estimate-detail/estimate-detail.component';
import { BoatProfileService } from './yacht-owner/boat-manager/boat-profile/boat-profile.service';
import { GoogleAnalyticsCustomService } from './services/google-analytics.service';
import { UpdateService } from './services/update.service';
import { Capacitor } from '@capacitor/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	public spinkit = Spinkit;
	title = 'yacht-watch-mobile';
	componentRef!: ComponentRef<any>;
	cookieValue: any;
	flagvalue: string;
	public isUpdateAvailable = false;
	public isUpdateInProgress = false;
	public availableVersion: string;
	networkStatusChangeValue = true;

	buildType: BuildType;

	isSlowInternet = false;
	slowInternetSubscription: Subscription;
	platform_mode: string;

	constructor(
		public baseService: BaseService,
		private navCtrl: NavController,
		private zone: NgZone,
		private screenOrientation: ScreenOrientation,
		public cookiesService: CookieService,
		private translate: TranslateService,
		private platform: Platform,
		private networkStatusCheck: NetworkStatusCheckService,
		public networkStatusChange: NetworkStatusCheckService,
		private inApp: InAppService,
		private metaService: Meta,
		private utilityService: UtilityService,
		private router: Router,
		private loading: LoadingController,
		private alertCtrl: AlertController,
		private environmentService: EnvironmentService,
		private popoverCtrl: PopoverController,
		private toastCtrl: ToastController,
		private toastService: ToastService,
		private yachtProfileProviderService: YachtMessagesService,
		private loaderService: LoaderService,
		private accountSettingService: AccountSettingService,
		private dataService: DataService,
		private signalrService: SignalrService,
		private modalCtrl: ModalController,
		private audioService: AudioService,
		private pushNotification: PushNotificationService,
		private facebookTrackingService: FacebookTrackingService,
		private boatProfileService: BoatProfileService,
		private googleAnalyticsCustomService: GoogleAnalyticsCustomService,
		private updateService:UpdateService
	) {

		App.addListener('appStateChange', async (state) => {
			if(state.isActive){
			  console.log("App is active")
			  this.updateService.checkForUpdate()
			}
		  })


		  this.updateService.onUpdateAvailable.subscribe((event)=>{
			if(event && event.needsUpdate && event.updateInProgress){
			 this.isUpdateInProgress = true;
			}else if(event && event.needsUpdate && !event.updateInProgress){
			  this.isUpdateAvailable = true;
			  this.availableVersion = event.availableVersion;
			}else{
			  this.isUpdateAvailable = false;
			  this.isUpdateInProgress = false;
			}
		  })
	}
	

	ngOnInit() {
		this.buildType = this.environmentService.buildType;
		this.initializeApp();
		this.preloadAudio();
		this.baseService.initDatabase();
		// this.handleHardwareBackButton();
	}

	updateApp(){
		this.updateService.openAppStore()
	  }
	

	async initializeApp() {
		this.cookieValue = localStorage.getItem('lang') || 'en';
			this.setFlag();
			this.translate.setDefaultLang(this.cookieValue);
		this.platform.ready().then(async () => {
			if (this.platform.is('capacitor')) {
				if (this.buildType === 'boatowner' && this.platform.is('ios')) {
					try {
						await this.facebookTrackingService.requestPermissions();
						await this.googleAnalyticsCustomService.requestTrackingPermission();
					} catch (error) {
						console.error('Error requesting permissions');
					}
				
				}
				await this.pushNotification.registerPushNotification();
			}

			this.initUniversalLinks();

			if (!isPlatform('capacitor')) {
				// TODO: Move to environment
				FacebookLogin.initialize({ appId: '1721835658255553' });
			}

			const rootElement = document.documentElement;

			if (this.platform.is('ios')) {
				const deviceWidth = screen.width;
				if (deviceWidth <= 350) {
					this.metaService.addTag({
						name: 'viewport',
						content:
							'width=device-width, initial-scale=0.8, maximum-scale=0.8, user-scalable=no',
					});
				}
				if (deviceWidth > 350 && deviceWidth <= 400) {
					this.metaService.addTag({
						name: 'viewport',
						content:
							'width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no',
					});
				}
			} else if (
				this.platform.is('android') &&
				!this.platform.is('mobileweb')
			) {
				await TextZoom.set({ value: 0.85 });
			}

			this.networkStatusChange.networkStatusChangeEvent.subscribe({
				next: (event) => {
					this.networkStatusChangeValue = event;
				},
			});

			// this.cameraService.fileDirectory = "YachtWatch";

			if (this.platform.is('capacitor')) {
				this.screenOrientation.lock(
					this.screenOrientation.ORIENTATIONS.PORTRAIT
				);
			}

			
			this.networkStatusCheck.listenToNetworkStatusChange();

			this.updateService.checkForUpdate()

		

			if (this.platform.is('capacitor')) {
				// await StatusBar.show({ animation: Animation.Fade });
				await StatusBar.setStyle({ style: Style.Dark });

				if (this.platform.is('android')) {
					await StatusBar.setBackgroundColor({ color: '#ffffff' });
				}
			}

			

			const sessionData: any = localStorage.getItem('sessionData');
			const user_data = JSON.parse(sessionData);
			const accessToken = user_data?.result?.accessToken;

			if (this.platform.is('cordova') && accessToken) {
				// TODO: Subscription reactivation
				// this.inApp.initialize();
			}

			const user = this.baseService.getSession(this.baseService.credKey);

			// Load API data if user is logged in.
			if (user) {
				this.loadLaunchApiData();
			}
			if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
				// Apply boxed-layout class when running as a web app
				this.platform_mode = 'desktop';
				document.body.style.background = '#00005ced';
			}
		});
	}

	private async initUniversalLinks() {
		if (Capacitor.isNativePlatform()) {
		  App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
			console.log("came here");
			this.prepareQueryParams(event.url);
		  });
		} else {
		  const url = window.location.href;
		  if (url.includes("?")) {
			this.prepareQueryParams(url);
		  }
		}
	  }
	
	  prepareQueryParams(url: string) {
		this.zone.run(() => {
		  const queryData = url.split("?");
		  const data = queryData[1].split("&").reduce((acc, item) => {
			const parts = item.split("=");
			acc[parts[0]] = parts[1];
			return acc;
		  }, {});
	
		  this.handleDeepLinking(data);
		});
	  }
	
	  private async handleDeepLinking(data) {
		const user = this.baseService.getSession(this.baseService.credKey);
		let url = "";
		let queryParams = null;
		// if (user) {
		const modal = await this.modalCtrl.getTop();
		if (modal) {
		  this.modalCtrl.dismiss();
		}
	
		if (data) {
		  const dataType = data.type;
		  const id = Number(data.id);
		  switch (dataType) {
			case DATA_TYPE.SERVICE_ORDER:
			  url = `/yacht-owner/service-orders/view/${id}`;
			  break;
			case DATA_TYPE.VENDOR:
			  url = `/yacht-owner/service-providers/active/${id}`;
			  break;
			case DATA_TYPE.INVOICE:
			  url = `/yacht-owner/invoices/${id}`;
			  break;
			case DATA_TYPE.PACKAGE:
			  url = `/yacht-owner/packages/${id}`;
			  break;
			case DATA_TYPE.BOAT_TRANSFER:
			  url = `/yacht-owner/boat-transfer/${id}`;
			  break;
			case DATA_TYPE.BOAT_MANAGER:
			  url = `/yacht-owner/boat-manager`;
			  break;
			case DATA_TYPE.ESTIMATE:
			  url = `/yacht-owner/estimates/${id}`;
			  break;
			case DATA_TYPE.NOTIFICATION:
			  url = `/yacht-owner/notifications`;
			  queryParams = { deeplink: true, type: "notification" };
			  break;
			default:
				url = "/";
			  break;
		  }
		}
		// } else {
		// 	this.dataService.openModal.next({
		// 		type: 'login',
		// 		isOpened: true,
		// 	});
		// }
	
		if (user) {
		  // if user is loggedin and the required query params are present, navigate to the respective page
		  if (data?.id || data?.type) {
			sessionStorage.setItem("isDeeplink", "true");
			this.router.navigate(['/captain-talk/ask-captain-ai']).then(()=>{ // tricked to navigate to the deeplink page so that there is a page to go back to from the deeplinked page
				if(queryParams){
					this.router.navigate([url], { queryParams });
				}else{
					this.router.navigate([url]);
				}
			})
			
		  }
		} else if (url !== "/") {
		  // if user is not loggedin, navigate to login page with the required returnUrl and also prevent
		  //  navigating to login if the user reloads the login page with the returnUrl ( this prevents infinite loop)
		  this.router.navigate(["/captain-talk/authentication/signin"], {
			queryParams: { returnUrl: url },
		  });
		}
	  }

	private async handleEstimateNotification(entityid) {
		const modal = await this.modalCtrl.create({
			component: EstimateDetailComponent,
			componentProps: {
				estimateId: entityid,
			},
		});
		modal.componentProps['modalRef'] = modal;

		modal.present();

		const { data } = await modal.onDidDismiss();
		this.router.navigate(['/yacht-owner/estimates']);
	}

	shouldRetry(response: HttpErrorResponse) {
		if (response.status === 0) {
			return timer(1000);
		}

		throw response;
	}

	async loadLaunchApiData() {
		this.loaderService.open();

		const retryCount = 2;
		const slowInternetWarningTime = 10000;
		const simulatedDelay = 0;
		const simulatedTimeout = 0;

		const slowInternetTimer$ = timer(slowInternetWarningTime).pipe(
			tap(() => {
				this.loaderService.setSlowInternet(true);
				console.warn('Slow internet connection detected.');
			}),
			finalize(() => {
				//
			})
		);

		if (environment.buildType === 'boatowner') {
			await this.boatProfileService.getCurrentBoat();
		}

		const guideVideos$ = this.utilityService.getGuideVideos().pipe(
			delay(simulatedDelay),
			retry({ count: retryCount, delay: this.shouldRetry }),
			catchError((error) => {
				console.error('Error fetching guide videos', error);
				throw error;
			})
		);

		const userChatSettings$ = from(
			this.signalrService.getFriendsChatsAndSettings()
		).pipe(
			delay(simulatedDelay),
			retry({ count: retryCount, delay: (error) => this.shouldRetry(error) }),
			catchError((error) => {
				console.error('Error fetching user chat settings', error);
				throw error;
			})
		);
		this.slowInternetSubscription = slowInternetTimer$.subscribe({
			next: () => {
				//
			},
		});

		const combined$ = combineLatest([
			guideVideos$,
			userChatSettings$,
			// userNotificationSettings$,
		]).pipe(
			take(1),
			catchError((error) => {
				console.error('Error loading API data', error);
				return throwError(() => new Error('Failed to load all API data'));
			}),
			finalize(() => {
				this.loaderService.close();
				this.loaderService.setSlowInternet(false);
				this.slowInternetSubscription.unsubscribe();
			})
		);

		combined$.subscribe({
			next: ([guideVideos, userChatSettings]) => {
				this.getGuideVideos(guideVideos.result);
				this.loaderService.close();
			},
			error: (error) => {
				console.error('Error after retries or other issue', error);
				this.loaderService.close();
			},
		});
	}

	preloadAudio() {
		this.audioService.configureAudio({ overrideBackgroundMusic: false });
		this.audioService.loadAudio();
	}

	getGuideVideos(guideVideos: any) {
		this.utilityService.initializeGuideVideos(guideVideos);
	}

	setFlag() {
		this.cookieValue = this.cookieValue || 'en';

		switch (this.cookieValue) {
			case 'en':
				this.flagvalue = 'assets/images/flags/us.jpg';
				break;
			case 'es':
				this.flagvalue = 'assets/images/flags/spain.jpg';
				break;
			default:
				this.cookieValue = 'en';
				this.flagvalue = 'assets/images/flags/us.jpg';
		}
	}

	handleHardwareBackButton() {
		this.platform.backButton.subscribeWithPriority(10, async () => {
			this.toastService.hideToasts();
			const loader = await this.loading.getTop();
			const popover = await this.popoverCtrl.getTop();
			const alert = await this.alertCtrl.getTop();
			const toast = await this.toastCtrl.getTop();
			const modal = await this.modalCtrl.getTop();
			if (loader) loader.dismiss();
			else if (popover) popover.dismiss();
			else if (alert) alert.dismiss();
			else if (toast) toast.dismiss();
			else if (modal) modal.dismiss();
			else if (
				this.router.url === '/yacht-owner/service-list' ||
				this.router.url === '/yacht-owner/yacht-messages' ||
				this.router.url === '/yacht-owner/boat-vitals' ||
				this.router.url === '/yacht-owner/boat-profile'
			) {
				await this.router.navigate(['/yacht-owner/dashboard']);
			} else if (
				this.router.url == '/yacht-owner/dashboard' ||
				this.router.url == '/technician/dashboard' ||
				this.router.url == '/yacht-owner/on-boarding' ||
				this.router.url == '/start'
			) {
				try {
					await this.CloseConfirm();
				} catch (error) {
					//
				}
			} else {
				this.navCtrl.back();
			}
		});
	}

	async CloseConfirm() {
		const type = this.buildType === 'boatowner' ? 'BoatBot' : 'BoatBot Tech';
		const alert = await this.alertCtrl.create({
			message:
				this.cookieValue === 'en'
					? `Are you sure you want to exit ${type}?`
					: `¿Estás seguro de que quieres salir de ${type}?`,
			buttons: [
				{
					text: this.cookieValue === 'en' ? 'Cancel' : 'Cancelar',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						//
					},
				},
				{
					text:
						this.cookieValue === 'en' ? 'Close App' : 'Cerrar la aplicación',
					handler: () => {
						navigator['app'].exitApp();
					},
				},
			],
		});

		await alert.present();
	}
}
