import { Component, Input } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { ModalController } from "@ionic/angular";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { PartService } from "../../pages/ask-captain-ai/ai-part-finder/part-finder-path/part.service";
import { UtilityService } from "../../services/utility.service";
import { ConversationComponent } from "../conversation/conversation.component";

@Component({
  selector: "app-feedback-box",
  templateUrl: "./feedback-box.component.html",
  styleUrls: ["./feedback-box.component.css"],
})
export class FeedbackBoxComponent {
  public isSendingPositiveFeedback = false;
  public isSendingNegativeFeedback = false;
  public isFeedBackGiven = false;
  public LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
  @Input() public componentTypeId: number = null;
  @Input() public yachtId: number = null;

  @Input() public firstPrompt: { prompt: string; context: string };
  @Input() public isConversational: boolean = true;
  @Input() public hasSubmitForm: boolean = false;

  @Input() public source: string = "default";
  @Input() public isDefaultSource = false;

  constructor(
    private modalCtrl: ModalController,
    private util: UtilityService,
    private partService: PartService
  ) {}

  private async openConversation() {
    const source = this.isDefaultSource ? "default" : "conversation";
    const prompt = this.firstPrompt?.prompt || "";
    let context = await this.getContextFromComponentDetails();

    this.firstPrompt = { prompt, context };

    const modal = await this.createModal(source);
    await modal.present();
    await modal.onDidDismiss();

    if (Capacitor.isNativePlatform()) {
      await Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  private async getContextFromComponentDetails(): Promise<string> {
    let context = this.firstPrompt?.context || "";

    try {
      if (this.componentTypeId && this.yachtId) {
        const data = await this.getComponentDetails();
        context = this.extractContextFromResult(data) || context;
      }
    } catch (error) {
      console.error(error);
    }

    return context;
  }

  private extractContextFromResult(data: any): string | undefined {
    if (data?.result?.metaData) {
      const componentData = JSON.parse(data.result.metaData);

      if (componentData?.component) {
        const model = componentData.component.model || "";
        const manufacturer = componentData.component.manufacturer?.value || "";
        const context = `${model} ${manufacturer}`.trim();
        return context;
      }
    }
    return undefined;
  }

  private async createModal(source: string): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: ConversationComponent,
      backdropDismiss: false,
      cssClass:"ai-conversation-modal",
      componentProps: {
        firstPrompt: this.firstPrompt,
        source,
      },
    });
    return modal;
  }

  public async thumbsDownClick() {
    if (this.isConversational == true) {
      this.openConversation();
    } else {
      this.isSendingNegativeFeedback = true;

      setTimeout(async () => {
        this.isSendingNegativeFeedback = false;
        if (this.source === "conversation" || this.source === "pre_prompt") {
          this.isFeedBackGiven = true;
        } else {
          if (this.hasSubmitForm) {
            this.util.showAlert({ custom_feedback: true });
          } else {
            const result = await this.util.showAlert({ service_request: true });
            console.log({ result });
          }
        }
      }, 2000);
    }
  }

  private getComponentDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isSendingNegativeFeedback = true;
      this.partService
        .getComponentDetails(this.yachtId, this.componentTypeId)
        .subscribe({
          next: (data) => {
            this.isSendingNegativeFeedback = false;
            resolve(data);
          },
          error: (err) => {
            this.isSendingNegativeFeedback = false;
            console.error(err);
            reject(err);
          },
        });
    });
  }

  public thumbsUpClick() {
    this.isSendingPositiveFeedback = true;

    setTimeout(() => {
      this.isSendingPositiveFeedback = false;

      this.isFeedBackGiven = true;
      this.util.showAlert({ positive_feedback: true });
    }, 2000);
  }
}
