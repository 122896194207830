import { Component, OnInit } from "@angular/core";
import { BaseService } from "../../shared/base.service";
import { Router } from "@angular/router";
import { GpsLocationService } from "../../technician/gps-location.service";
import { PermissionCheckerService } from "abp-ng2-module";
import { environment } from "src/environments/environment";
import { LogoutService } from "../../yacht-owner/logout.service";
import { AbpApplicationService } from "src/shared/abp-application.service";
import { LanguageService } from "../../utilities/language.service";
import { BoatProfileService } from "src/app/yacht-owner/boat-manager/boat-profile/boat-profile.service";
import {OnBoardingService} from "../../yacht-owner/on-boarding/on-boarding.service";
@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.css"],
})
export class DefaultComponent implements OnInit {
  constructor(
    private baseService: BaseService,
    public abpService: AbpApplicationService,
    private router: Router,
    private locationService: GpsLocationService,
    private permissionChecker: PermissionCheckerService,
    private logoutService: LogoutService,
    private languageService: LanguageService,
    private boatProfileService: BoatProfileService,
    private onBoardingService: OnBoardingService
  ) {}

  ngAfterViewInit() {
    console.log("default component after view init");
  }

  async ngOnInit() {
    console.log("default component");
    const user = this.baseService.getSession(this.baseService.credKey);
    await this.abpService.updateAbp().toPromise();
    if (user) {
      this.baseService.authenticated = true;
      await this.languageService.fetchLanguageInfo();
      if (environment.buildType === "boatowner" && this.permissionChecker.isGranted("Pages.YachtOwners")) {
        const returnUrl = sessionStorage.getItem("returnUrl")
        const isDeeplink = sessionStorage.getItem("isDeeplink");
        if (await this.onBoardingService.isOnboarding()) {
          await this.router.navigate(["/captain-talk/onboarding/add-boat-information-basic"]);
        }  else if(returnUrl){
          await this.router.navigate([decodeURIComponent(returnUrl)], { replaceUrl: true });
          // clear the return url from session storage after redirecting
          sessionStorage.removeItem("returnUrl");
        }
        else{
          // check if the user has come from a deeplink in order to prevent redirection to the ask captain ai page
          if(isDeeplink){
            sessionStorage.removeItem("isDeeplink");
          }else{
            await this.router.navigate(["/captain-talk/ask-captain-ai"]);
          }
          
        }
       
      } else if (environment.buildType === "technician" && this.permissionChecker.isGranted("Pages.Technicians")) {
        this.locationService.setTrackingValue(true);
        this.router.navigate(["/technician/dashboard"]);
      } else {
        this.logoutService.logout();
      }
    }
  }
}
